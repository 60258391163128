.footer-container {
    background-color: #242424;
    /* background-color: #002e27; */
    padding: 2.5rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }

  .social-media {
    max-width: 1200px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 1rem 10px 7rem;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 140px;
    margin-right: 1.8em;
  }

  .linkedin, 
  .github,
  .devpost,
  .mail
   {
    transition: all .3s ease-in-out;
  }

  .linkedin:hover, 
  .github:hover,
  .devpost:hover,
  .mail:hover
   {
    transform: scale(1.3);
    color: #8fdcc2;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin: 0px 10px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    text-align: center;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
      margin: 1rem 1rem;
      justify-content: space-around;
      gap: 1rem;
    }

    .social-icons {
      margin-right: 0;
    }
  }
  
  @media screen and (max-width: 768px) {
  }