.spotify-container {
    background-color: #2E4940;
    padding: 2.5rem 0 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 17rem;
    height: fit-content;
    padding: 1em;
    border-radius: 100px;
    margin: 3em 0;
    color: #8fdcc2;
    font-size: small;
  }

  .song-name {
    color: #8fdcc2;
    text-decoration: none;
    font-weight: 600;
  }

  .song-name:hover {
    color: #e2fff6;
  }

  .spotify-innercontainer {
    display: flex;
    flex-direction: column;
  }

  .spotlogo {
    margin-right: 1em;
    transition: transform 330ms ease-in-out;
  }

  .spotlogo:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 820px) {
    .spotify-container {
        margin: .5em;
    }
  }
  