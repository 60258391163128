* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', Helvetica, sans-serif;
}

.container,
.project-container {
  display: flex;
  margin: 1rem 10rem 4rem;
  font-size: 20px;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.letter-space {
  letter-spacing: 1px;
}

.container-left {
  display: flex;
  margin: 3rem 8rem 4rem;
  font-size: 20px;
  letter-spacing: 1px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  object-fit: contain;
}

.container-left>p {
  margin-bottom: 2rem;
}

.contact-item {
  color: #000;
  text-decoration: none;
  margin: 1rem 1rem;
}

.contact-item:hover {
  text-decoration: underline;
}

hr {
  width: 90%;
  margin: auto;
  height: 8px;
  background: black;
}

.headshot {
  border-radius: 25px;
  width: 35%;
  margin: 2em 3em;
}

.gif {
  float: right;
}

.frog {
  width: 15em;
  transform: scaleX(-1);
  margin-top: -10rem;
  margin-right: 10rem;
}

.dog {
  width: 20em;
  margin-top: -11rem;
  margin-right: 5rem;
}

.about,
.projects,
.contact {
  width: auto;
  color: #000;
  text-align: left;
  font-size: 100px;
  margin: 3rem auto 1rem 5rem;
}

@media only screen and (max-width: 1304px) {
  .container {
    flex-direction: column;
    margin: 1rem 5rem;
    align-items: center;
  }

  .container-left {
    margin: 3rem 6rem 4rem;
  }

  .project-container {
    flex-direction: column;
    margin: 1rem 2rem;
    align-items: center;
  }

  .headshot {
    width: 40%;
  }

}


@media screen and (max-width: 960px) {

  .headshot {
    width: 80%;
  }

  .gif {
    width: 15em;
    margin-right: 2rem;
  }

  .frog {
    margin-top: -6rem;
  }

  .dog {
    margin-top: -2rem;
  }
}

@media screen and (max-width: 700px) {
  .about,
  .projects,
  .contact {
    font-size: 60px;
    margin: 3rem auto 1rem auto;
    text-align: center;
  }
}