/* ['btn--primary', 'btn--outline', 'btn--outline--invert', 'btn--test'];
['btn--medium', 'button--large']; */

:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline--invert {
    background-color: white;
    color: black;
    padding: 8px 20px;
    border-radius: 10px;
    border: 1px solid #242424;
    transition: all 0.3s ease-out;
    margin-top: 10px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
}

.btn--outline--invert:hover {
    background-color: #242424;
    color: white;
    transition: all 0.3s ease-out;
}

/* .first-txt {
    position: absolute;
    top: 50%;
    left:14rem;
    text-align: center;
    vertical-align: middle;
    color: white;
    opacity: 0;
    transition: all 0.3s ease-out;
}

.cards__item__img:hover .first-txt{
    opacity: 1;
} */

